import React from 'react'
import codegirl from '../../images/hero-girl.svg'
import LinkedIn from '../../images/linkedin.svg'
import Github from '../../images/github.svg'
import Email from '../../images/email.svg'
import Twitter from '../../images/twitter.svg'
import Medium from '../../images/medium.svg'
import Typewriter from "typewriter-effect";
import './Hero.css'
import { HeroContainer, HeroIcon, HeroLeft, HeroRight, HeroContext, HeroImg, IconContainer } from './HeroElements'



const Hero = () => {
  return (
    <HeroContainer className="row" id="home">
      <HeroLeft className="col-md-8">
        <HeroContext>
        <Typewriter
          options={{
            autoStart: true,
            loop: true,
            strings:["Hello World!", "My name is Megan. :)"]
          }} 
          className="typewriter"
        />
        <p>I am a software engineer based in San Francisco Bay Area, CA.</p>
        </HeroContext>
        <IconContainer>
          <a href="mailto:hkmeganlo@gmail.com" data-title="Click to Email Me!" target="_blank" rel="noreferrer">
            <HeroIcon src={Email} alt="Email icon" />
          </a>
          <a href="https://www.linkedin.com/in/megan-s-lo/" data-title="Connect With Me on LinkedIn!" target="_blank" rel="noreferrer">
            <HeroIcon src={LinkedIn} alt="LinkedIn icon" />
          </a>
          <a href="https://github.com/mehmehmehlol" data-title="Check Out My Recent Projects!" target="_blank" rel="noreferrer">
            <HeroIcon src={Github} alt="Github icon" />
          </a>
          <a href="https://twitter.com/meglothedev" data-title="Follow Me On Twitter!" target="_blank" rel="noreferrer">
            <HeroIcon src={Twitter} alt="Twitter icon" />
          </a>
          <a href="https://medium.com/@meganslo" data-title="Check Out My Blogs on Medium.com!" target="_blank" rel="noreferrer">
            <HeroIcon src={Medium} alt="Medium icon" />
          </a>
          <a href="https://dev.to/mehmehmehlol" data-title="Check Out My Blogs on dev.to!" target="_blank" rel="noreferrer">
            <HeroIcon src="https://d2fltix0v2e0sb.cloudfront.net/dev-badge.svg" alt="Megan Lo's DEV Community Profile" style={{ height: "56px", width: "56px", fill: "white" }} />
          </a>
        </IconContainer>
      </HeroLeft>
      <HeroRight className="col-md-4">
        <HeroImg src={codegirl} alt="girl in pink" />
      </HeroRight>
    </HeroContainer>
  )
}

export default Hero
