import React from 'react'
import LinkedIn from '../../images/linkedin.svg'
import Github from '../../images/github-white.svg'
import Email from '../../images/email-white.svg'
import Twitter from '../../images/twitter.svg'
import Medium from '../../images/medium-white.svg'
import { FooterSection, FooterImg, FooterDevImg, FooterContainer, IconContainer, FooterDiv, FooterLink } from './FooterElements'

const Footer = () => {
  return (
    <FooterSection>
      <FooterContainer>
        <IconContainer>
          <FooterLink href="mailto:hkmeganlo@gmail.com" title="Click to Email Me!" target="_blank" rel="noreferrer">
            <FooterImg src={Email} alt="Email icon"/>
          </FooterLink>
          <FooterLink href="https://www.linkedin.com/in/megan-s-lo/" title="Connect With Me on LinkedIn!" target="_blank" rel="noreferrer">
            <FooterImg src={LinkedIn} alt="LinkedIn icon" />
          </FooterLink>
          <FooterLink href="https://github.com/mehmehmehlol" title="Check Out My Recent Projects!" target="_blank" rel="noreferrer">
            <FooterImg src={Github} alt="Github icon" />
          </FooterLink>
          <FooterLink href="https://twitter.com/meglothedev" title="Follow Me On Twitter!" target="_blank" rel="noreferrer">
            <FooterImg src={Twitter} alt="Twitter icon"/>
          </FooterLink>
          <FooterLink href="https://medium.com/@meganslo" title="Check Out My Blogs on Medium.com!" target="_blank" rel="noreferrer">
            <FooterImg src={Medium} alt="Medium icon" />
          </FooterLink>
          <FooterLink href="https://dev.to/mehmehmehlol" title="Check Out My Blogs on dev.to!" target="_blank" rel="noreferrer">
            <FooterDevImg src="https://d2fltix0v2e0sb.cloudfront.net/dev-badge.svg" alt="Megan Lo's DEV Community Profile"  />
          </FooterLink>
        </IconContainer>
        <FooterDiv>&#169; Copyright 2022 Megan Lo</FooterDiv>
      </FooterContainer>
    </FooterSection>
  )
}

export default Footer
