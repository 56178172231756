import React from 'react'
import { ProjectSection, ProjectTitle, ProjectImg, ProjectContainer, ProjectsContainer } from './ProjectsElement'
import './Projects.css'
import MoodJournal from '../../images/projects-screenshot/mood-journal.png'
// import PeanutPlanner from '../../images/projects-screenshot/peanut-event.png'
import SightSeeing from '../../images/projects-screenshot/sightseeing-in-hk.png'
import STEMStartHer from '../../images/projects-screenshot/STEMStartHer.png'
import Educating from '../../images/projects-screenshot/educating-young-eyes.png'
import GithubLogo from '../../images/github-white.svg'

const Projects = () => {
  return (
    <ProjectSection id="projects">
      <ProjectsContainer>
        <ProjectTitle>Featured Projects</ProjectTitle>
        <div className="projects-center">
        <ProjectContainer className="project project-1">
            <ProjectImg className="project-img" src={Educating} alt="Educating Young Eyes Screenshot"/>
            <div className="project-info">
              <h3 className="link-title">Educating Young Eyes (Volunteer Project)</h3>
              <a className="link" href="https://educatingyoungeyes.org/" target="_blank" rel="noreferrer">Demo</a>
            </div>
          </ProjectContainer>
          <ProjectContainer className="project project-2">
            <ProjectImg className="project-img" src={STEMStartHer} alt="STEMStartHer Screenshot"/>
            <div className="project-info">
              <h3 className="link-title">STEMStartHer</h3>
              <a className="link-left" href="https://stemstarther.netlify.app/" target="_blank" rel="noreferrer">Demo</a>
              <div className="vertical"></div>
              <a className="link-right" href="https://github.com/mehmehmehlol/STEMStartHer-frontend" target="_blank" rel="noreferrer"><img src={GithubLogo} alt="Github Logo"/></a>
            </div>
          </ProjectContainer>
          <ProjectContainer className="project project-3">
            <ProjectImg className="project-img" src={SightSeeing} alt="SightSeeing Screenshot" />
            <div className="project-info">
              <h3 className="link-title">Sightseeing in Hong Kong</h3>
              <a className="link-left" href="https://sightseeing-in-hk.netlify.app/" target="_blank" rel="noreferrer">Demo</a>
              <div className="vertical"></div>
              <a className="link-right" href="https://github.com/mehmehmehlol/sightseeing-in-hk-client" target="_blank" rel="noreferrer"><img src={GithubLogo} alt="Github Logo"/></a>
            </div>
          </ProjectContainer>
          <ProjectContainer className="project project-4">
            <ProjectImg className="project-img" src={MoodJournal} alt="Mood Journal Screenshot" />
            <div className="project-info">
              <h3 className="link-title">Mood Journal</h3>
              <a className="link-left" href="https://moodjournal-client.herokuapp.com/" target="_blank" rel="noreferrer">Demo</a>
              <div className="vertical"></div>
              <a className="link-right" href="https://github.com/mehmehmehlol/moodjournal-client" target="_blank" rel="noreferrer"><img src={GithubLogo} alt="Github Logo"/></a>
            </div>
          </ProjectContainer>
        </div>
      </ProjectsContainer>
    </ProjectSection>
  )
}
// https://makitweb.com/html-how-to-show-text-above-image-on-hover/

export default Projects
