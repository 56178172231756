import React from 'react'
import { SkillsContainer, SkillsSection, FlipCardContainer, SkillsTitle, FlipCard, FlipCardInner, FlipCardFront, FlipCardBack, FlipCardTitle, FlipCardContent } from './SkillsElements'
import './Skills.css'

const Skills = () => {
  return (
    <SkillsSection id="skills">
      <SkillsContainer>
        <SkillsTitle>Skills</SkillsTitle>
        {/* front-end */}
        <FlipCardContainer className="row">
        <FlipCard className="flip-card col-xs-12 col-md-4">
          <FlipCardInner className="flip-card-inner">
            <FlipCardFront>
              <FlipCardTitle>Front-End</FlipCardTitle>
            </FlipCardFront>
            <FlipCardBack>
              <FlipCardContent>
                <li>HTML & CSS</li>
                <li>JavaScript (ES6)</li>
                <li>ReactJS</li>
              </FlipCardContent>
            </FlipCardBack>
          </FlipCardInner>
        </FlipCard>  
        {/* backend */}
        <FlipCard className="flip-card col-xs-12 col-md-4">
          <FlipCardInner className="flip-card-inner">
            <FlipCardFront>
              <FlipCardTitle>Back-End</FlipCardTitle>
            </FlipCardFront>
            <FlipCardBack>
              <FlipCardContent>
                <li>Ruby</li>
                <li>Ruby on Rails</li>
              </FlipCardContent>
            </FlipCardBack>
          </FlipCardInner>
        </FlipCard> 
        {/* database  */}
        <FlipCard className="flip-card col-xs-12 col-md-4">
          <FlipCardInner className="flip-card-inner">
            <FlipCardFront>
              <FlipCardTitle>Database</FlipCardTitle>
            </FlipCardFront>
            <FlipCardBack>
              <FlipCardContent>
                <li>SQLite</li>
                <li>PostgreSQL</li>
              </FlipCardContent>
            </FlipCardBack>
          </FlipCardInner>
        </FlipCard> 
        </FlipCardContainer>
      </SkillsContainer>
      
    </SkillsSection>
  )
}

export default Skills
