import styled from 'styled-components';

export const ProjectSection = styled.div`
  background: #BFEFFF;
`;

export const ProjectsContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
  background: #BFEFFF;

  @media screen and (max-width: 1024px) { 
    width: 90%;
  }
`;

export const ProjectTitle = styled.h1`
  padding: 10px;
  font-weight: bold;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) { 
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
  }
`;

export const ProjectContainer = styled.div`
  position: relative;
  background: #23395D;
  border-radius: 0.5rem;
  margin: 0 auto;
  height: 300px;
  width: 475px;
  justify-content: center;
  align-items: center;
  box-shadow: 0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2);
  -webkit-transform: transform .2s;
  transform: transform .2s;

  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 768px) { 
    height: 100%;
    width: 90%;
  }
`

export const ProjectImg = styled.img`
  height: 100%;
  width: 100%;
`;