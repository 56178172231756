import React, { useState, useEffect } from 'react'
import { BlogsSection, BlogsContainer, BlogsTitle, Blog, BlogImg, BlogDisplay, BlogTitle, BlogDate, BlogContent, BlogButtonContainer, BlogButton, Url, PublisherTitle, ButtonUrl } from './BlogsElements'
import axios from 'axios';
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

const Blogs = () => {
  const [medium, setMedium] = useState([]);
  const [dev, setDev] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchData = async () => {
    setIsError(false);
    setIsLoading(true);
    
    try {
      const mediumAPI = 'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@meganslo';
      const devAPI = "https://dev.to/api/articles?username=mehmehmehlol"

      const getMedium = axios.get(mediumAPI)
      const getDev = axios.get(devAPI)
      axios.all([getMedium, getDev])
        .then(
          axios.spread((...allData) => {
            const medium = allData[0].data.items.slice(0, 4)
            const dev = allData[1].data.slice(0, 4)

            setMedium(medium)
            // console.log(medium)
            setDev(dev)
            // console.log(dev)
          })
        )
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
  };

  function titleSlice(title) {
    if (title.length > 40) {
      title = title.slice(0, 40) + "..."
    }
    return title;
  }
    

  // fetch data from Medium API and dev.to API
  useEffect(() => {
    fetchData();
  }, [])

  return (
    <BlogsSection id="blogs">
      <BlogsContainer>
        <BlogsTitle>Recent Blogs</BlogsTitle>
        <>
        {isError && <Blog>Something's not right</Blog>}
        {isLoading ? (
        <ClimbingBoxLoader color={"#23395D"} size={20} style={{ marginTop: "20px" }}/> 
      ) : (
        <div>
          <BlogButtonContainer>
            <PublisherTitle>dev.to</PublisherTitle>
            <BlogButton><ButtonUrl rel="noreferrer" target="_blank" href="https://dev.to/mehmehmehlol">View More</ButtonUrl></BlogButton>
          </BlogButtonContainer>
          <BlogDisplay className="row">
            {dev.map((blog, index) => (
              <Blog key={index}>
                <Url href={blog.url} rel="noreferrer" target="_blank">
                  <BlogImg  src={blog.social_image} alt={blog.title}/>
                  <BlogContent className="blog-content">
                    <BlogTitle>{blog.title.slice(0, 50)}</BlogTitle>
                    <BlogDate>Posted On: <strong>{blog.published_at.slice(0, 10)}</strong></BlogDate>
                  </BlogContent>
                </Url>
              </Blog>
            ))}
          </BlogDisplay>

          <BlogButtonContainer>
            <PublisherTitle>Medium</PublisherTitle>
            <BlogButton><ButtonUrl rel="noreferrer" target="_blank" href="https://medium.com/@meganslo">View More</ButtonUrl></BlogButton>
          </BlogButtonContainer>
          <BlogDisplay className="row"> 
          {/* put view more next to the publisher name Medium (View More) */}
            {medium.map((blog, index) => (
              <Blog key={index}>
                <Url href={blog.link} rel="noreferrer" target="_blank">
                  <BlogImg  src={blog.thumbnail} alt={blog.title}/>
                  <BlogContent className="blog-content">
                    <BlogTitle>{titleSlice(blog.title)}</BlogTitle>
                    <BlogDate>Posted On: <strong>{blog.pubDate.slice(0, 10)}</strong></BlogDate>
                  </BlogContent>
                </Url>
              </Blog>
            ))}
          </BlogDisplay>        
        </div>
        )}
        </>
        
      </BlogsContainer>
    </BlogsSection>
  )
}

export default Blogs
