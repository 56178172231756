import styled from 'styled-components';

export const JourneyContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and (max-width: 768px) { 
    padding-bottom: 0;
  }

  @media screen and (max-width: 1024px) { 
    width: 90%;
  }
`;

export const JourneyTitle = styled.h1`
  padding: 10px;
  font-weight: bold;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) { 
    font-size: 30px;
    text-align: center;
    margin-bottom: 0;
  }
`;

export const TimelineContainer = styled.div`
  font-family: 'PT Mono', monospace;
`;

export const Title = styled.h2`
  font-size: 30px;
  padding-bottom: 10px;
`
export const Subtitle = styled.h2`
  font-size: 20px;
  padding-bottom: 10px;
`

export const Content = styled.p`
  font-size: 15px;
  padding-top: 10px;
`