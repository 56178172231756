import styled from 'styled-components';

export const AboutMe = styled.div`
  background: #BFEFFF;
`;

export const AboutContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and (max-width: 1024px) { 
    width: 90%;
  }
`;

export const AboutTitle = styled.h1`
  padding: 10px;
  font-weight: bold;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) { 
    font-size: 30px;
    text-align: center;
  }
`;

export const AboutContent = styled.div`

`;

