import styled from 'styled-components'

export const FooterSection = styled.div`
  background: #23395D;
`

export const FooterContainer = styled.div`

`

export const IconContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`

export const FooterImg = styled.img`
  margin-top: 30px;
  height: 40px;
  margin-right: 40px;

  @media screen and (max-width: 768px) {
    height: 30px;
    margin-top: 20px;
    margin-right: 20px;
  }
`;

export const FooterDevImg = styled.img`
  margin-top: 30px;
  height: 56px;
  margin-right: 40px;

  @media screen and (max-width: 768px) {
    height: 42px;
    margin-top: 15px;
    margin-right: 0;
    top: 0;
  }
`;

export const FooterDiv = styled.div`
  color: #FFFFFF;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 15px;
  font-size: 25px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`

export const FooterLink = styled.a`
  @media screen and (max-width: 768px) {
    height: 50px;
  }
`