import React, { useState, useEffect } from 'react'
// import { Link } from 'react-scroll'
import {animateScroll as scroll } from 'react-scroll'
import { Nav, NavbarContainer, NavItem, NavLinks, NavLogo, NavMenu, MobileIcon } from './NavbarElements'
import { FaBars } from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import { CgProfile } from 'react-icons/cg'
import { MdTimeline } from 'react-icons/md'
import { FiTool } from 'react-icons/fi'
import { BiPencil, BiCodeAlt } from 'react-icons/bi'

const Navbar = ({ toggle, isOpen }) => {
  const [scrollNav, setScrollNav] = useState(false)

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  })

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#23395D'}}>
        <Nav scrollNav={ scrollNav } isOpen={isOpen} >
          <NavbarContainer>        
            <NavLogo onClick={toggleHome} to="/" style={{ textDecoration: "none", color: "#23395D" }}>Megan Lo</NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks 
                  to="about-me"
                  smooth={true}
                  duration={300}
                  spy={true}
                  exact='true'
                  offset={-80}
                ><CgProfile /> About Me</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks 
                  to="skills"
                  smooth={true}
                  duration={300}
                  spy={true}
                  exact='true'
                  offset={-80}
                ><FiTool /> Skills</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks 
                  to="projects"
                  smooth={true}
                  duration={300}
                  spy={true}
                  exact='true'
                  offset={-80}
                ><BiCodeAlt /> Projects</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks 
                  to="journey"
                  smooth={true}
                  duration={300}
                  spy={true}
                  exact='true'
                  offset={-80}
                ><MdTimeline /> My Coding Journey</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks 
                  to="blogs"
                  smooth={true}
                  duration={300}
                  spy={true}
                  exact='true'
                  offset={-80}
                ><BiPencil />Recent Blogs</NavLinks>
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav> 
      </IconContext.Provider>
    </>
  )
}

export default Navbar
