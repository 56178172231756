import React from 'react'
import { AboutMe, AboutContainer, AboutTitle, AboutContent } from './AboutElements'
import './About.css'
import MyPic from '../../images/me.jpeg'

const About = () => {
  return (
    <AboutMe id="about-me">
      <AboutContainer>
        <AboutTitle>About Me</AboutTitle>
        <div className="window">
          <div className="bar">
            <div className="buttons">
              <div className="close"></div>
              <div className="minimalize"></div>
              <div className="zoom"></div>
            </div>
          </div>
          <AboutContent className="content row">
            <div className="col-md-4 image">
              <img src={MyPic} alt="Me" />
            </div>
            <div className="col-md-8 about-content">
              <p>I found my passion in programming back in college but never pursued it. Even though I took the classes for fun, those were the best times I ever had in college. Graduated with a sociology degree with a strong passion in contributing back to society, my goal is to combine my web development skills and my knowledge in sociology to build platforms for people who want to speak up and address social issues that matters to them.</p>
              <p>Aside from web developing, I love solo traveling (unfortunately have to put a halt due to the pandemic) and baking Cantonese pastries. In my free time, you would find me watching **HELLA** Netflix, or catching up with my family and friends, or... looking for Snoopy-related products.</p>
              <p>Random fun fact about me is that I like getting tanned but I am not a beach-goer. I grew up in Hong Kong 🇭🇰, which is a semi-tropical city and I am very much a city girl who likes to be surrounded with skyscrapers. 🏙</p>
            </div>
          </AboutContent>
        </div>
      </AboutContainer>
    </AboutMe>
  )
}

export default About

