import styled from 'styled-components';

export const SkillsSection = styled.div`

`;

export const SkillsContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and (max-width: 1024px) { 
    width: 90%;
  }
`;

export const SkillsTitle = styled.h1`
  padding: 10px;
  font-weight: bold;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) { 
    font-size: 30px;
    text-align: center;
    margin-bottom: 10px;
  }
`;

export const FlipCardContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;

  @media screen and (max-width: 768px) { 
    display: grid;
    grid-template-rows: 0.7fr;
    -webkit-row-gap: 2rem;
    -moz-row-gap: 2rem;
    row-gap: 1rem;
  }
`

export const FlipCard = styled.div`
  background-color: transparent;
  width: 250px;
  height: 300px;
  perspective: 1000px;

  @media screen and (max-width: 768px) { 
    margin: auto;
    box-shadow: none;
  }
`

export const FlipCardInner = styled.div`
  position: relative;
  width: 90%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;

  @media screen and (max-width: 768px) { 
    width: 100%;
  }
`;

export const FlipCardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  background-color: #FCEBF6;
  color: #23395D;
  border-radius: 20px;
  box-shadow: 0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.1);

  /* @media screen and (max-width: 768px) { 
    backface-visibility: visible;
  } */
`;

export const FlipCardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  background-color: #ECE3FC;
  color: #23395D;
  transform: rotateY(180deg);
  border-radius: 20px;
  box-shadow: 0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.1);
`;

export const FlipCardTitle = styled.h3`
  margin-top: 40%;
`
export const FlipCardContent = styled.ul`
  margin-top: 30%;
  font-size: 25px;
  list-style-type: none;
`


