import styled from 'styled-components'

export const HeroContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 120px 30px;
  /* margin: 130px auto 50px auto ; */
  height: 450px;
  z-index: 1;

  @media screen and (max-width: 768px) {
    margin: 70px auto 20px auto;
    padding-top: 30px;
    padding-bottom: 40px;
    height: 200px;
  }

  @media screen and (max-width: 1024px) and (min-width: 769px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const IconContainer = styled.div`

`;

export const HeroLeft = styled.div`
  padding: 2rem;

`;

export const HeroContext = styled.div`
  font-size: 30px;

  @media screen and (max-width: 768px) {
    font-size: 17px;
  }

  @media screen and (max-width: 1024px) and (min-width: 769px) {
    font-size: 23px;
  }
`;

export const HeroIcon = styled.img`
  padding-right: 20px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const HeroRight = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const HeroImg = styled.img`
  height: 200px;
  margin: 0 20px;
  float: right;
`;