import styled from 'styled-components'

export const BlogsSection = styled.div`
  background: #BFEFFF;
`;

export const BlogsContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and (max-width: 1024px) { 
    width: 90%;
  }
`;

export const BlogsTitle = styled.h1`
  padding: 10px;
  font-weight: bold;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) { 
    font-size: 30px;
    text-align: center;
  }
`;

export const BlogLink = styled.a`
  text-decoration: none;
`

export const BlogDisplay = styled.div`
  margin: 30px auto;
  grid-template-rows: repeat(1, 350px);
  grid-template-columns: repeat(4, 275px);
  gap: 1rem;
  display: grid;
  width: 100%;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 325px);
    grid-template-rows: 2fr;
    -webkit-row-gap: 2rem;
    -moz-row-gap: 2rem;
    row-gap: 1rem;
    align-items: center;
    justify-content: center
  }

  @media screen and (max-width: 1024px) and (min-width: 769px) {
    grid-template-columns: repeat(2, 0.5fr);
    gap: 0.5rem;
   }
`

export const PublisherTitle = styled.h2`
  padding: 10px;
  font-weight: bold;
  /* margin-bottom: 30px; */

  @media screen and (max-width: 768px) { 
    font-size: 20px;
    text-align: center;
  }
`;

export const Blog = styled.div`
  margin: 0;
  margin-left: 5px;
  margin-bottom: 20px;
  background: white;
  border-radius: 15px;
  box-shadow: 0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.3);
  -webkit-transform: transform .2s;
  transform: transform .2s;

  &:hover {
    transform: scale(1.1);
  }
`

export const BlogImg = styled.img`
  width: 100%;
  height: 65%;
  border-radius: 15px 15px 0 0;
`

export const BlogContent = styled.div`
  margin: 0 5%;
`

export const BlogTitle = styled.h4`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
`

export const BlogDate = styled.div`
  font-size: 15px;
  text-align: center;
  bottom: 0%;
  /* padding: 10px; */
`

export const BlogButtonContainer = styled.div`
  display: flex;
  /* margin: 0 auto; */
  /* margin-top: 50px; */
  /* justify-content: center; */
  /* align-items: center; */
`

export const BlogButton = styled.button`
  height: 50px;
  padding: 10px;
  margin-top: 5px;
  margin-left: 10px;
  background: #ffffff;
  border: none;
  border-radius: 15px;
  box-shadow: 0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2);
  -webkit-transform: transform .2s;
  transform: transform .2s;

  &:hover {
    background: #FCEBF6;
    transform: scale(1.1);
  }

  @media screen and (max-width: 768px) { 
    padding: 10px;
    margin-top: 0px;
    border-radius: 10px;
  }
`
export const ButtonUrl = styled.a`
  text-decoration: none;
  color: #23395D;
  font-size: 20px;
  padding: 7px;
`

export const Url = styled.a`
  text-decoration: none;
  color: #23395D;
`