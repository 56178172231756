import React from 'react'
// import { Timeline, Event } from "react-timeline-scribble";
import { JourneyContainer, JourneyTitle, TimelineContainer, Title, Subtitle, Content } from './JourneyElements'
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react'

const Journey = () => {
  return (
    <JourneyContainer id="journey">
      <JourneyTitle>My Coding Journey</JourneyTitle>
        <TimelineContainer>
          <Timeline lineColor={"#23395D"}>
            <TimelineItem
              key="001"
              dateText="Sep 2017"
              dateInnerStyle={{ background: '#FCEBF6', color: '#23395D'}}
              bodyContainerStyle={{
                background: '#FCEBF6',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.1)',
              }}
              style={{ color: '#23395D'}}
            >
              <Title>My First Computer Science Class </Title>
              <Subtitle>at Shoreline Community College</Subtitle>
              <Content>
                Although I had already graduated with an A.A. degree in Business Adminstration in community college, I decided to stay for another year to try to pursue and see if I can transfer to a 4-year college as a Math major, as I enjoyed studying Calculus. One of the prerequisites was to finish a CS class that was equivalent to "Intro to Java" in my CC. Therefore, in my last 2 quarters in CC, I took a Python (prerequisite to the Java class) and Java class. I completely fell in love with the adrenaline of problem solving. I had never been more diligent in my academic career. But once I transferred to UC Berkeley as a non-STEM major, I thought that was the end of my programming journey.
              </Content>
            </TimelineItem>
            <TimelineItem
              key="002"
              dateText="Apr 2020 - Aug 2020"
              dateInnerStyle={{ background: '#BFEFFF', color: '#23395D'}}
              bodyContainerStyle={{
                background: '#BFEFFF',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.1)',
              }}
              style={{ color: '#23395D'}}
            >
              <Title>The Discovery of My Love to Web Development</Title>
              <Subtitle>The first couple months of the pandemic</Subtitle>
              <Content>
                I was in my last semester at Berkeley and I had only a couple months left till graduation. I had an intention to be a recruiter, because I love helping and working with people. My ultimate goal was to become a tech recruiter. It was always my dream to work in tech even it was a non-tech position. But then I also remembered how much I miss programming and I had been proudly telling my friends at Berkeley that I used to learn programming. 
              </Content>
              <Content>
                Without many activities and mobilities during this time, I decided to do some research on programming and I discovered there was a thing called "Web Development" in the software engineering field. With some great online resources, like Codecademy and W3Schools, even though it was just HTML & CSS, I absolutely fell in love with that. I then made up my mind at that time that I would do my best to step my foot in this amazing field.   
              </Content>
            </TimelineItem>
            <TimelineItem
              key="003"
              dateText="Aug 2020 - Dec 2020"
              dateInnerStyle={{ background: '#FCEBF6', color: '#23395D'}}
              bodyContainerStyle={{
                background: '#FCEBF6',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.1)',
              }}
              style={{ color: '#23395D'}}
            >
              <Title>15 Weeks of Intensive Learning in Full-Stack Web Development</Title>
              <Subtitle>at Flatiron School</Subtitle>
              <Content>
                After some serious discussion with my parents and tons of research on what types of learning would be best for me to gain the practical skills, I decided to choose Flatiron School as the place where I would be provided with structured schedule and a network of peers and instructors. I was absolutely amazed how much we learned to make a web app from start to finish within 4 months. Although the program was very intensive and I definitely had my fair share of complaint, the hard work was all worth it. I fell in love with web development even more and I am so eager to learn more and make the websites even better.   
              </Content>
            </TimelineItem>
            <TimelineItem
              key="004"
              dateText="Feb 2021 - Sep 2021"
              dateInnerStyle={{ background: '#BFEFFF', color: '#23395D'}}
              bodyContainerStyle={{
                background: '#BFEFFF',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.1)',
              }}
              style={{ color: '#23395D'}}
            >
              <Title>Job Searching</Title>
              <Subtitle>Perfect Time to Gain Skills</Subtitle>
              <Content>
                We all know job searching is not an easy thing. It drains you mentally and physically. In order to distract myself with these exhausting feelings, I have been volunteering in a project to help developing their website working with the UI/UX team, as well as the backend team. 
              </Content>
              <Content>
                I recently participated in an All-Women Hackathon and won 2<sup>nd</sup> place! The best part is that I gained the experience working with the UI/UX team and project managers! I admire their talents and I feel so lucky to be able to work with these amazing and talented women.    
              </Content>
            </TimelineItem>
            <TimelineItem
              key="005"
              dateText="Sep 2021 - Jul 2022"
              dateInnerStyle={{ background: '#FCEBF6', color: '#23395D'}}
              bodyContainerStyle={{
                background: '#FCEBF6',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.1)',
              }}
              style={{ color: '#23395D'}}
            >
              <Title>1st Software Engineer Job!</Title>
              <Subtitle>@Olive AI</Subtitle>
              <Content>
                The 8-month job search finally came to an end! I am currently working at a healthcare AI company based in Ohio, called Olive AI. I couldn't be more grateful with this opportunity. I am learning a lot from this job :)
              </Content>
            </TimelineItem>
          </Timeline>
        </TimelineContainer>
    </JourneyContainer>
  )
}

export default Journey
