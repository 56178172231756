import React, { useState } from 'react'
import About from '../components/AboutSection/About';
import Blogs from '../components/Blogs/Blogs';
import Footer from '../components/Footer/Footer';
import Hero from '../components/HeroSection/Hero';
import Journey from '../components/Journey/Journey';
import Navbar from '../components/Navbar/Navbar'
import Projects from '../components/Projects/Projects';
import Sidebar from '../components/Sidebar/Sidebar';
import Skills from '../components/Skills/Skills';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} isOpen={isOpen}/>
      <Hero />
      <About />
      <Skills />
      <Projects />
      <Journey />
      <Blogs />
      <Footer />
    </>
  )
}

export default Home
