import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink } from './SidebarElements' 
import { CgProfile } from 'react-icons/cg'
import { MdTimeline } from 'react-icons/md'
import { FiTool } from 'react-icons/fi'
import { BiPencil, BiCodeAlt } from 'react-icons/bi'

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle} >
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink
            to='about-me'
            onClick={toggle}
            smooth={true}
            duration={300}
            spy={true}
            exact='true'
            offset={-80}
          >
            <CgProfile /> About Me
          </SidebarLink>
          <SidebarLink
            to="skills"
            onClick={toggle}
            smooth={true}
            duration={300}
            spy={true}
            exact='true'
            offset={-80}
          >
            <FiTool /> Skills
          </SidebarLink>
          <SidebarLink
            to='projects'
            onClick={toggle}
            smooth={true}
            duration={300}
            spy={true}
            exact='true'
            offset={-80}
          >
            <BiCodeAlt /> Projects
          </SidebarLink>
          <SidebarLink
            to='journey'
            onClick={toggle}
            smooth={true}
            duration={300}
            spy={true}
            exact='true'
            offset={-80}
          >
            <MdTimeline /> My Coding Journey
          </SidebarLink>
          <SidebarLink
            to="blogs"
            onClick={toggle}
            smooth={true}
            duration={300}
            spy={true}
            exact='true'
            offset={-80}
          >
            <BiPencil /> Recent Blogs
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
